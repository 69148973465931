"use client";
import { TurnkeyProvider } from "@turnkey/sdk-react";
import { CivicAuthProvider as CivicNextAuthProvider } from "@civic/auth/nextjs";
import React, { type ReactNode, useCallback, useState } from "react";
import type { NextCivicAuthWeb3ProviderProps } from "../types.js";
import { useTurnkeyNonce } from "../hooks/turnkey/useTurnkeyNonce.js";
import { turnkeyConfig } from "../lib/turnkey/turnkeyApiClient.js";
import { Web3UserProvider } from "../components/Web3UserProvider.js";

// A wrapper around civic auth that injects a Turnkey embedded wallet.
// Turnkey requires that a nonce be passed in when the user logs in to civic-auth,
// in order to tie the oauth login process to the current turnkey session.
function CivicAuthWrapperProvider({
  children,
  ...props
}: NextCivicAuthWeb3ProviderProps & {
  children: ReactNode;
}) {
  // allow the embedded wallet to add a hook to the logout process, ensuring its state gets cleaned up
  // for security reasons, and to avoid bugs when changing users
  const [logoutCallback, setLogoutCallback] = useState<() => Promise<void>>();
  const registerLogout = useCallback(
    (callback: () => Promise<void>) => setLogoutCallback(() => callback),
    [setLogoutCallback],
  );

  // calling turnkey outside the auth provider, so we can get the nonce to be used for the oauth login
  // NOTE: at this point, the user has not "logged in" to Turnkey,
  // so there is no current session.
  // Once the civic auth user exists, we can log into Turnkey using this user.
  const nonce = useTurnkeyNonce();

  if (!nonce) {
    console.log("Turnkey iframe not ready - skipping the rest of the app");
    return null;
  }

  return (
    <CivicNextAuthProvider
      {...props}
      nonce={nonce} // Pass the nonce from turnkey in here
      onSignOut={async () => {
        await logoutCallback?.();
      }}
    >
      <Web3UserProvider registerLogoutCallback={registerLogout} {...props}>
        {children}
      </Web3UserProvider>
    </CivicNextAuthProvider>
  );
}

export function CivicAuthProvider({
  children,
  ...props
}: NextCivicAuthWeb3ProviderProps & { children: ReactNode }) {
  console.log("CivicAuthProvider props", props);
  return (
    <TurnkeyProvider
      config={{
        ...turnkeyConfig,
        // the wallet API is the API for creating wallets (/wallet) and the turnkey proxy (/proxy)
        // the latter is used here
        ...(props.endpoints?.wallet
          ? { serverSignUrl: `${props.endpoints.wallet}/proxy` }
          : {}),
      }}
    >
      <CivicAuthWrapperProvider {...props}>{children}</CivicAuthWrapperProvider>
    </TurnkeyProvider>
  );
}
