"use client";

import { FC, PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { embeddedWallet } from "@civic/auth-web3";
import { CivicAuthProvider } from "@civic/auth-web3/nextjs";
import { polygonAmoy, baseSepolia } from "viem/chains";
import { Chain, http } from "viem";
import { createConfig, WagmiProvider } from "wagmi";

const queryClient = new QueryClient();

// Configure chains and RPC URLs.
const supportedChains = [baseSepolia, polygonAmoy] as [Chain, ...Chain[]];

const wagmiConfig = createConfig({
  chains: supportedChains,
  transports: {
    [baseSepolia.id]: http(),
    [polygonAmoy.id]: http(),
  },
  connectors: [
    embeddedWallet({ debug: true }),
  ],
  ssr: true,
});

// Add this type for the Providers props
type ProvidersProps = PropsWithChildren<{
  onSessionEnd?: () => void;
}>;

export const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={wagmiConfig}>
          <CivicAuthProvider modalIframe={false} config={{
            oauthServer: process.env.NEXT_PUBLIC_AUTH_SERVER!
          }}>
            {children}
          </CivicAuthProvider>
        </WagmiProvider>
      </QueryClientProvider>
  );
};
