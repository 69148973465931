import { createCivicAuthPlugin as internalCivicAuthPlugin } from "@civic/auth/nextjs";
import type { AuthConfig } from "@civic/auth/nextjs";
import type { NextConfig } from "next";
import { defaultAuthConfig } from "./config.js";
export {
  createTokenCookies,
  createUserInfoCookie,
  clearAuthCookies,
  NextjsCookieStorage,
  NextjsClientStorage,
  getUser,
  handler,
} from "@civic/auth/nextjs";
export type {
  AuthConfig,
  CookiesConfigObject,
  AuthConfigWithDefaults,
  DefinedAuthConfig,
} from "@civic/auth/nextjs";
export { CivicAuthProvider } from "./CivicAuthProvider.js";

/**
 * We override the httpOnly flag of the id_token cookie to false,
 * so that turnkey can access it on the frontend.
 */
export const createCivicAuthPlugin = (
  authConfig: AuthConfig = {},
): ((nextConfig?: NextConfig) => NextConfig) => {
  const useAuthConfig = { ...authConfig, clientId: authConfig.clientId! };
  if (!useAuthConfig.cookies?.tokens.id_token) {
    useAuthConfig.cookies = {
      ...(defaultAuthConfig.cookies || {}),
      ...(useAuthConfig.cookies || {}),
      tokens: {
        ...(defaultAuthConfig.cookies?.tokens || {}),
        ...(useAuthConfig.cookies?.tokens || {}),
        ["id_token"]: {
          secure: true,
          httpOnly: false,
          sameSite: "strict",
          path: "/",
        },
      },
    };
  }
  return internalCivicAuthPlugin(useAuthConfig);
};
